<template>
  <v-container fluid px-11>
    <v-row class="align-center">
      <v-col cols="12" md="7">
        <v-text-field class="mr-6" prepend-inner-icon="search" label="Search" v-model="search"></v-text-field>
      </v-col>
      <v-col cols="12" md="5">
        <v-row>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click="filterDialog= true" color="primary">
                <v-icon left>mdi-filter-variant</v-icon>Filter Users
              </v-btn>
            </template>
            <span>Filter users based on their roles or permissions</span>
          </v-tooltip>
          <v-btn
            v-if="filtered"
            @click="getAllUsersForBusiness();filtered=false"
            color="darkGrey"
            text
            class="text-capitalize ml-5"
          >
            <v-icon left>mdi-cancel</v-icon>Clear Filters
          </v-btn>
        </v-row>
        <!-- <v-autocomplete
          multiple
          chips
          clearable
          v-model="roles.selected"
          :items="roles.data"
          label="Filter By Roles"
          cache-items
          item-text="display_name"
          auto-select-first
          return-object
          @change="filterUsersByRole"
        ></v-autocomplete>-->
      </v-col>
    </v-row>
    <v-skeleton-loader type="table" :loading="pageLoading" :transition="'slide-y-transition'">
      <v-data-table :search="search" class="background mr-6" :headers="headers" :items="users">
        <template v-slot:item.actions="{ item }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <v-btn
                elevation="0"
                @click="openUserSettings(item)"
                slot="activator"
                fab
                small
                dark
                v-on="on"
                color="primary"
                :class="$vuetify.breakpoint.width > 960 ? 'ml-0 mr-3 xs-small' : 'ml-3 xs-small'"
              >
                <v-icon small>fas fa-cog</v-icon>
              </v-btn>
            </template>
            <span>Settings</span>
          </v-tooltip>
        </template>
        <template v-slot:item.is_corporate="{ item }">
          <v-switch
            @change="toggleCorporate({'enable': $event}, item)"
            v-model="item.is_corporate"
            inset
            color="primary"
          ></v-switch>
        </template>
        <template v-slot:item.active="{ item }">
          <v-switch
            @change="toggleStatus({'enable': $event}, item)"
            v-model="item.active"
            inset
            color="primary"
          ></v-switch>
        </template>
        <template v-slot:item.admin_access="{ item }">
          <v-switch
            @change="toggleAdmin({'enable': $event}, item)"
            v-model="item.admin_access"
            inset
            color="primary"
          ></v-switch>
        </template>
      </v-data-table>
    </v-skeleton-loader>

    <v-dialog
      v-if="filterDialog"
      v-model="filterDialog"
      scrollable
      persistent
      max-width="850"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-text>
          <v-tabs grow>
            <v-tab>Roles</v-tab>
            <v-tab>Permissions</v-tab>
            <v-tab-item>
              <v-card flat>
                <v-card-title primary-title>Filter By Roles</v-card-title>
                <v-card-subtitle>Filter list of users by selecting one or multiple roles</v-card-subtitle>
                <v-card-text class="pb-0">
                  <v-autocomplete
                    multiple
                    chips
                    clearable
                    v-model="roles.selected"
                    :items="roles.data"
                    label="Filter By Roles"
                    cache-items
                    item-text="display_name"
                    auto-select-first
                    return-object
                  ></v-autocomplete>
                </v-card-text>
                <v-card-actions>
                  <v-checkbox label="Require All" v-model="roles.requireAll"></v-checkbox>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="darkGrey"
                    @click="filterDialog = false"
                    text
                    class="text-capitalize"
                  >Cancel</v-btn>
                  <v-btn color="primary" @click="filterUsersByRole(roles.selected)">Filter</v-btn>
                </v-card-actions>
                <!-- <v-row>
                  <v-checkbox
                    v-for="role in roles.data"
                    :key="role.id"
                    @change
                    :label="role.display_name"
                    hide-details
                    class="pa-0 my-1"
                  ></v-checkbox>
                </v-row>-->
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-title primary-title>Filter By Role-Permissions</v-card-title>
                <v-card-subtitle>Filter list of users by selecting permissions for a selected role</v-card-subtitle>
                <v-card-text>
                  <v-autocomplete
                    v-model="applications.selected"
                    :items="applications.data"
                    label="Choose application"
                    cache-items
                    item-text="display_name"
                    auto-select-first
                    return-object
                    @change="getPermissionsForRole"
                  ></v-autocomplete>
                  <span v-for="res in editRoleDialog.resources" :key="res.id">
                    <tree-view
                      @permission-selected="handlePermissionSelected"
                      @permission-removed="handlePermissionRemoved"
                      @resource-selected="handleResourceSelected"
                      @resource-removed="handleResourceRemoved"
                      :node="res"
                    ></tree-view>
                    <v-divider class="mx-1"></v-divider>
                  </span>
                </v-card-text>
                <v-card-actions>
                  <v-checkbox label="Require All" v-model="editRoleDialog.requireAll"></v-checkbox>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="darkGrey"
                    @click="filterDialog = false"
                    text
                    class="text-capitalize"
                  >Cancel</v-btn>
                  <!-- <v-btn  @click="closeModal"  color="darkGrey" text class="text-capitalize">Cancel</v-btn> -->
                  <v-btn
                    @click="filterUsersByPermission"
                    elevation="0"
                    color="primary"
                    class="text-capitalize"
                  >Filter</v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </v-dialog>
    <user-settings-modal
      v-if="userSettingsModal.status"
      @close-modal="closeUserSettingsModal"
      :status="userSettingsModal.status"
      :user="userSettingsModal.user"
    />
    <invite-user-modal
      v-if="inviteUserModal.status"
      @close-modal="inviteUserModal.status = false"
      :status="inviteUserModal.status"
      @fetch-all-users="getAllUsersForBusiness"
    />
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn @click="inviteUser" fab fixed dark bottom right color="primary" v-on="on">
          <v-icon small>fas fa-user-plus</v-icon>
        </v-btn>
      </template>
      <span>Invite a user</span>
    </v-tooltip>
  </v-container>
</template>

<script>
import { AuthAPI } from '@/clients/auth/auth.api'
import { mapGetters } from 'vuex'
import { RolesAPI } from '../../clients/permissions/roles'
import { ApplicationsAPI } from '../../clients/permissions/apps'
import TreeView from '@/components/custom-components/TreeView'
import _ from 'lodash'
import { UsersAPI } from '@/clients/permissions/users'

export default {
  components: {
    UserSettingsModal: () => import('./UserSettingsModal'),
    InviteUserModal: () => import('./InviteUserModal'),
    TreeView
  },
  async mounted() {
    this.getAllUsersForBusiness()
    this.getAllRoles()
    this.getAllApplications()
  },
  data() {
    return {
      users: [],
      headers: [
        {
          text: 'First Name',
          align: 'start',
          sortable: true,
          value: 'first_name'
        },
        {
          text: 'Last Name',
          align: 'start',
          sortable: true,
          value: 'last_name'
        },
        {
          text: 'Email',
          align: 'start',
          sortable: true,
          value: 'email'
        },
        {
          text: 'Active',
          align: 'start',
          sortable: true,
          value: 'active'
        },
        {
          text: 'Corp Admin',
          align: 'start',
          sortable: true,
          value: 'is_corporate'
        },
        {
          text: 'Admin Panel Access',
          align: 'start',
          sortable: true,
          value: 'admin_access'
        },
        {
          text: 'Actions',
          align: 'start',
          sortable: false,
          value: 'actions'
        }
      ],
      userSettingsModal: {
        status: false,
        user: null
      },
      inviteUserModal: {
        status: false
      },
      search: '',
      roles: {
        data: [],
        selected: [],
        requireAll: false
      },
      pageLoading: false,
      filterDialog: false,
      editRoleDialog: {
        status: false,
        resources: [],
        selected: null,
        requireAll: false
      },
      permissions: [],
      applications: {
        data: [],
        selected: null,
        loading: false
      },
      filtered: false
    }
  },
  methods: {
    async getAllApplications() {
      if (this.applications.data.length > 0) {
        return
      }
      let response
      try {
        response = await ApplicationsAPI.getAllApps()
      } catch (error) {
        console.log(error)
      }
      this.applications.data = response.data.payload
    },
    async getPermissionsForRole(app) {
      this.spinner(true)
      let response
      try {
        response = await ApplicationsAPI.getAllResourcesForAnApp(app.id, true, false)
      } catch (error) {
        this.spinner(false)
        this.editRoleDialog.resources = []
        console.log(error)
        return
      }
      this.spinner(false)
      for (const item of response.data.payload) {
        this.recursivePermissionAdd(item)
      }
      this.editRoleDialog.resources = response.data.payload
      this.editRoleDialog.status = true
    },
    async recursivePermissionAdd(node) {
      for (const childNode of node.children) {
        this.recursivePermissionAdd(childNode)
      }
      for (const perm of node.permissions) {
        perm.selected = false
      }
    },
    async handlePermissionSelected({ perm, node }) {
      // check if resource already present
      let index = this.permissions.findIndex(i => i.app_resource_name === node.name)
      if (index > -1) {
        this.permissions[index].permissions.push(perm.name)
      } else {
        let data = {
          app_resource_name: node.name,
          permissions: [
            perm.name
          ]
        }
        this.permissions.push(data)
      }
    },
    async handlePermissionRemoved({ perm, node }) {
      let index = this.permissions.findIndex(i => i.app_resource_name === node.name)
      if (index > -1) {
        let permIndex = this.permissions[index].permissions.findIndex(i => i === perm.name)
        if (permIndex > -1) {
          this.permissions[index].permissions.splice(permIndex, 1)
        }
        if (this.permissions[index].permissions.length < 1) {
          this.permissions.splice(index, 1)
        }
      }
    },
    async handleResourceSelected({ resource }) {
      let index = this.permissions.findIndex(i => i.app_resource_name === resource.name)
      if (index > -1) {
        this.permissions.splice(index, 1, ...this.getAllPermissionNames(resource))
      } else {
        this.permissions.push(...this.getAllPermissionNames(resource))
      }
    },
    async handleResourceRemoved({ resource }) {
      console.log(resource.children)

      let index = this.permissions.findIndex(i => i.app_resource_name === resource.name)
      if (index > -1) {
        this.permissions.splice(index, 1)
      }
      // check recursive
      for (const childNode of resource.children) {
        this.handleResourceRemoved({ resource: childNode })
      }
    },
    getAllPermissionNames(resource) {
      if (resource.permissions.length < 1) return
      let resourceData = {
        app_resource_name: resource.name,
        permissions: resource.permissions.map(i => i.name)
      }
      let data = []
      if (this.permissions.findIndex(i => i.app_resource_name === resource.name) === -1) {
        data.push(resourceData)
      }
      resource.children.forEach(i => {
        data.push(...this.getAllPermissionNames(i))
      })
      return data
    },
    async getAllUsersForBusiness() {
      this.pageLoading = true
      let response
      try {
        response = await AuthAPI.getUsersForABusiness(this.vg_activeBusiness.id)
      } catch (error) {
        console.log(error)
        this.pageLoading = false
      }
      this.users = response.data.payload
      this.pageLoading = false
    },
    async openUserSettings(user) {
      this.userSettingsModal.user = user
      this.userSettingsModal.status = true
    },
    closeUserSettingsModal() {
      this.userSettingsModal = {
        status: false,
        user: null
      }
    },
    async toggleCorporate(data, user) {
      let response
      this.spinner(true)
      try {
        response = await AuthAPI.toggleCorporateUser(user.id, data)
      } catch (error) {
        console.log(user)
        user.is_corporate = !user.is_corporate
      }
      console.log(response)
      this.spinner(false)
    },
    async toggleStatus(data, user) {
      this.spinner(true)
      try {
        await AuthAPI.toggleUserStatus(user.id, data)
      } catch (error) {
        user.active = !user.active
      }
      this.spinner(false)
    },
    async toggleAdmin(data, user) {
      this.spinner(true)
      try {
        await AuthAPI.toggleAdminUser(user.id, data)
      } catch (error) {
        user.admin_access = !user.admin_access
      }
      this.spinner(false)
    },
    inviteUser() {
      this.inviteUserModal = {
        status: true
      }
    },
    async getAllRoles() {
      this.pageLoading = true
      let response
      try {
        response = await RolesAPI.getAllRoles()
      } catch (error) {
        this.pageLoading = false
        console.log(error)
      }
      this.roles.data = response.data.payload
      this.pageLoading = false
    },
    async getUsersWithSpecificRoles(roles, require_all = false) {
      this.pageLoading = true
      let response
      try {
        response = await RolesAPI.filterUsersByRole({ role_names: roles, require_all })
      } catch (error) {
        if (error.response.status === 404) {
          this.users = []
          this.pageLoading = false
        }
        this.pageLoading = false

        console.log(error)
      }
      this.users = response.data.payload
      this.pageLoading = false
    },
    filterUsersByRole(roles) {
      if (roles.length > 0) {
        this.getUsersWithSpecificRoles(roles.map(i => i.name), this.roles.requireAll)
      } else {
        this.roles.requireAll = false
        this.getAllUsersForBusiness()
      }
      this.filtered = true
      this.clearFilters()
    },
    async filterUsersByPermission() {
      this.pageLoading = true
      let response
      try {
        response = await UsersAPI.getUsersWithSpecificPermissions({ permissions: this.permissions, require_all: this.editRoleDialog.requireAll })
      } catch (error) {
        if (error.response.status === 404) {
          this.users = []
          this.clearFilters()
        }
        this.pageLoading = false
        this.filtered = true
        console.log(error)
      }
      this.users = response.data.payload
      this.pageLoading = false
      this.clearFilters()
      this.filtered = true
    },
    clearFilters() {
      this.permissions = []
      this.roles.selected = []
      this.roles.requireAll = false
      this.filterDialog = false
    }
  },
  computed: {
    ...mapGetters({
      vg_activeBusiness: 'auth/activeBusiness'
    }),
    watcher_rolesRequireAllToggle() {
      return this.roles.requireAll
    },
    _resources() {
      return _.clone(this.resources)
    },
    filteredResources() {
      if (this.search) {
        return this._resources
          .filter(el => {
            return el.permissions.some(child => {
              return child.display_name
                .toLowerCase()
                .match(this.search.toLowerCase())
            })
          })
          .map(el => {
            let newElt = Object.assign({}, el) // copies element
            return {
              ...newElt,
              permissions: newElt.permissions.filter(permission => {
                return permission.display_name
                  .toLowerCase()
                  .match(this.search.toLowerCase())
              })
            }
          })
      }
      return this._resources
    }
  },
  watch: {
    // watcher_rolesRequireAllToggle: {
    //   handler: function (x) {
    //     if (this.roles.selected.length > 0) {
    //       this.getUsersWithSpecificRoles(this.roles.selected.map(i => i.name), x)
    //     }
    //   }
    // }
  }

}
</script>

<style lang="scss" scoped>
.v-data-footer {
  padding-right: 25px !important;
}
</style>
